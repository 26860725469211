   import React from 'react';
          import {View, Text, Image, StyleSheet, TouchableOpacity, Linking} from 'react-native';

          const App = () => {
          return (
          <View style={styles.container}>
          <Image
              source={require('./assets/logo.png')} // Update the path to your logo image
              style={styles.logo}
          />
          <Text style={styles.tagline}>Your Values, Your Investments: Our Commitment.</Text>
          <Text style={styles.heading}>Empowering Your Financial Decisions with Values-Driven Investments</Text>
          <Text style={styles.comingSoon}>Coming Soon</Text>
          <TouchableOpacity onPress={() => Linking.openURL('mailto:info@guardianinvestmentbd.com')}>
              <Text style={styles.email}>Email us: info@guardianinvestmentbd.com</Text>
          </TouchableOpacity>
      </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000' // Set background color to black
  },
  logo: {
    width: 200,
    height: 200,
    resizeMode: 'contain'
  },
  tagline: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#FFF' // Set text color to white for visibility
  },
  heading: {
    marginTop: 10,
    fontSize: 16,
    textAlign: 'center',
    color: '#FFF' // Set text color to white for visibility
  },
  comingSoon: {
    marginTop: 20,
    fontSize: 24,
    fontWeight: 'bold',
    color: '#FFD700' // Gold color for emphasis
  },
  email: {
    marginTop: 20,
    fontSize: 16,
    color: '#00FF00' // Green color to match the company theme
  }
});

export default App;
